@charset "utf-8";

@import "minima";

.p3-posts {
    position: relative;
    max-width: 680px;
    /*just an example*/
}

.p3-post {
    max-width: 720;
    display: inline-block;
    /*also an example*/
    h3 {
        margin-bottom: 8px;
    }
    img {
        max-width: 128px;
        height: auto;
        border-radius: 5px;
        display: inline;
        float: left;
        margin-right: 20px;
        margin-bottom: 20px;
    }
    p {
        text-align: justify;
        line-height: 25px;
        /*adding a bit of spacing between the lines*/
    }
    i {
        font-size: 12px;
        font-weight: lighter;
    }
}

.site-nav {
    z-index: 1;
}

.small-caption {
    font-size: 10px;
    text-align: center;
}

.centered {
    text-align: center;
}

.howto-image {
    display: inline-block;
    width: 100%;
}

.howto-image-img {
    float: left;
    margin-right: 25px;
    margin-bottom: 15px;
}

.howto-image-caption {
    text-align: justify;
    height: 100%;
    margin-top: 2px;
    ol {
        margin-left: 20px;
    }
    ul {
        margin-left: 20px;
    }
}

.tiny-previews {
    display: inline-block;
    width: 100%;

    text-align: center;
}

.preview-tiny {
    position: relative;
    display: inline-block;
    padding: 10px 10px;
    max-width: 128px;

    .tiny-img {
        width: 100%;
        img {
            display: block;
            margin-left: auto;
            margin-right: auto;
            border-radius: 5px;
        }
    }
    .tiny-caption {
        &-top {
            width: 100%;
            text-align: center;
            font-size: 10px;
            p {
                margin-bottom: 0px;
            }
        }
        &-bot {
            width: 100%;
            text-align: center;
            font-size: 10px;
        }
    }
}

.insta-embedd {
    width: 320px;
    margin: 0 auto;
}

.insta-dual-parent {
    display: inline-block;
}

.insta-embedd-dual {
    width: 320px;
    margin: 0 25px;
    float: left;
}

.shop-hint {
    font-size: 8px;
    font-weight: lighter;
}

p > .prj-title {
   margin-bottom: 0px;

}
